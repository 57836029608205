<template>
  <section v-if="itemsOptions.length" class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-between invoice-spacing mt-0">
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper position-relative">
                    <logo
                      class="position-absolute"
                      style="top: 7px"
                      height="75"
                    />
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-end invoice-spacing">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="addOrder"
                    >
                      <feather-icon icon="SaveIcon" />
                    </b-button>
                  </div>
                  <div
                    class="d-flex justify-content-between invoice-number-date flex-column flex-md-row"
                  >
                    <div class="d-flex align-items-start mb-2 mr-md-1">
                      <span class="title"> {{ $t("orderDate") }}: </span>
                      <b-form-datepicker
                        v-model="invoiceData.orderdate"
                        :locale="$i18n.locale"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: '2-digit',
                        }"
                        placeholder=""
                        class="form-control invoice-edit-input"
                      />
                    </div>
                    <div class="d-flex align-items-start">
                      <span class="title"> {{ $t("dueDate") }}: </span>
                      <b-form-datepicker
                        v-model="invoiceData.duedate"
                        :locale="$i18n.locale"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: '2-digit',
                        }"
                        placeholder=""
                        class="form-control invoice-edit-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                />
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="4">
                          {{ $t("productName") }}
                        </b-col>
                        <b-col cols="12" lg="3">
                          {{ $t("term") }}
                        </b-col>
                        <b-col cols="12" lg="3">
                          {{ $t("unit") }}
                        </b-col>
                        <b-col cols="12" lg="2">
                          {{ $t("amount") }}
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="4">
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            transition=""
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            :placeholder="$t('productName')"
                            @input="(val) => updateItemForm(index, val)"
                          />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label class="d-inline d-lg-none">Vade Name</label>
                          <v-select
                            transition=""
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="vadeNames"
                            label="vadeadi"
                            :clearable="false"
                            :selectable="(option) => item.productname != ''"
                            class="mb-2 item-selector-title"
                            :placeholder="$t('term')"
                            @input="(val) => updateItemForm(index, val)"
                          />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label class="d-inline d-lg-none">Unit</label>
                          <v-select
                            transition=""
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="[
                              { label: 'Çuval (50 KG)', value: 1 },
                              { label: 'Ton', value: 2 },
                              { label: 'Kilo', value: 3 },
                            ]"
                            :clearable="false"
                            :selectable="(option) => item.productname != ''"
                            class="mb-2 item-selector-title"
                            :placeholder="$t('unit')"
                            @input="
                              (val) => {
                                item.unitid = val.value;
                              }
                            "
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                            @input="
                              (val) => {
                                invoiceData.items[index].qty = val;
                                $forceUpdate();
                              }
                            "
                          />
                        </b-col>
                        <b-col v-if="item.cost" cols="12" lg="5">
                          <label class="d-inline d-lg-none">Birim Fiyat</label>
                          <p class="mb-1">
                            {{ $t("unitPrice") }} : TL{{ item.cost }}
                          </p>
                        </b-col>
                        <b-col cols="12" lg="5">
                          <label class="d-inline d-lg-none">Price</label>
                          <p class="mb-1">
                            {{ $t("price") }} : TL{{ item.cost * item.qty }}
                          </p>
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                {{ $t("add") }}
              </b-button>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">{{ $t("note") }}: </span>
              <b-form-textarea v-model="invoiceData.note" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { getUserData } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueI18n from "@/libs/i18n";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BFormTextarea,
    vSelect,
    Logo,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "addOrder") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/plants",
        text: VueI18n.t("plants"),
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("plant"),
        to: `/plant/${to.params.id}`,
        active: false,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("plantOrders"),
        to: `/plant/${to.params.id}/orders`,
        active: false,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("newOrder"),
        active: true,
      });
    }
    next();
  },
  mixins: [heightTransition],
  data: function () {
    return {
      lastSelected: [],
      itemsOptions: [],
      clients: [],
      itemFormBlankItem: {
        id: "",
        karisim: "",
        kod: "",
        max: "",
        min: "",
        price: 0,
        productname: "",
        selected: "",
        vade1: "",
        vade2: "",
        vade3: "",
        vade4: "",
        qty: 0,
        cost: 0,
      },
      vadeNames: [],
      invoiceData: {
        id: 0,
        client: null,
        // ? Set single Item in form for adding data

        items: [],
        orderdate: "",
        duedate: "",
        salesPerson: "",
        note: "",
        paymentMethod: null,
      },
      prices: {},
    };
  },
  async mounted() {
    const { data } = await this.$http.get("/Ingredients/GetProductsforOrder", {
      headers: {
        culture: getUserData().cultureinfo,
        plantid: this.$store.state.app.selectedPlantId,
      },
    });
    const prices = await this.$http.get("/Ingredients/GetProductPrices/");
    this.prices = prices.data.resultdata;
    this.invoiceData.items = [
      JSON.parse(JSON.stringify(this.itemFormBlankItem)),
    ];
    const vadeNames = await this.$http.get("/Ingredients/GetVadeNames/");
    this.vadeNames = vadeNames.data.resultdata;
    this.itemsOptions = data.resultdata.map((x) => {
      return { ...x, label: x.productname, qty: 1, cost: 0 };
    });
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    async addOrder() {
      var values = this.invoiceData.items.map((x) => {
        return {
          productid: x.id,
          orderamount: parseInt(x.qty),
          orderunitid: x.unitid,
          termid: x.termid,
          price: parseFloat(x.price),
        };
      });
      var order = {
        plantid: this.$store.state.app.selectedPlantId,
        additionalinfo: this.invoiceData.note,
        orderdate: this.invoiceData.orderdate,
        duedate: this.invoiceData.duedate,
        values: values,
      };
      var response = await this.$http.post("/Orders/AddOrder", order);
      if (response.data.status == "OK") this.$router.go(-1);
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    updateItemForm(index, val) {
      if (val.sira != undefined) {
        var selectedPrices = {
          ...this.prices.filter((p) => {
            return this.invoiceData.items[index].id == p.id;
          })[0],
        };
        this.invoiceData.items[index].termid = val.sira;
        if (val.sira == 0) {
          this.invoiceData.items[index].cost = selectedPrices[`price`];
        } else
          this.invoiceData.items[index].cost =
            selectedPrices[`vade${val.sira}`];
      } else {
        this.lastSelected[index] = { ...val };
        this.invoiceData.items[index] = { ...val };
      }
      this.$forceUpdate();

      // this.invoiceData.items[index].qty = qty;
      // this.invoiceData.items[index].description = description;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
